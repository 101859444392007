<template>
  <div class="edit-password">
    <div class="bd">
      <div class="title">修改密码</div>
      <div class="form">
        <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          @submit="handleSubmit"
        >
          <a-form-item label="用户名">
            <a-input size="large" :value="currentUser.account" disabled/>
          </a-form-item>
          <a-form-item label="旧密码">
            <a-input-password size="large" v-decorator="['oldPwd',{rules: [{ required: true, message: '请输入旧密码！' }]}]" />
          </a-form-item>
          <a-form-item label="新密码">
            <a-input-password size="large" v-decorator="['newPwd',{rules: [{ required: true, message: '请输入新密码！' },{validator: validateToNextPassword}]}]"/>
          </a-form-item>
          <a-form-item label="确认密码">
            <a-input-password size="large" v-decorator="['confirm',{rules: [{ required: true, message: '请再次输入新密码！' },{validator:compareToFirstPassword}]}]" @blur="handleConfirmBlur"/>
          </a-form-item>
          <a-row>
            <a-col :span="6" :offset="5">
              <div class="button">
                <a-button type="primary" size="large" html-type="submit"
                  >保存修改</a-button
                >
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apis from '@/business/apis'
export default {
  data () {
    return {
      form: this.$form.createForm(this, { name: 'modify_password' })
    }
  },
  computed: {
    ...mapState('user', [
      'currentUser'
    ])
  },
  methods: {
    async handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.name = this.currentUser.account
          let result
          if (this.currentUser.isOrgAdmin) {
            result = await this.$http.post(apis.orgAdmin.modifyPwd, values)
          } else {
            result = await this.$http.post(apis.schoolAdmin.modifyPwd, values)
          }
          if (result && result.body.success) {
            this.$message.success('修改成功')
          }
        }
      })
    },
    handleConfirmBlur (e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    compareToFirstPassword (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('newPwd')) {
        const msg = '两次密码输入不一致！'
        callback(msg)
      } else {
        callback()
      }
    },
    validateToNextPassword (rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    }
  }
}
</script>

<style lang="less">
.edit-password {
  .bd {
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 0 16px;
    > .title {
      padding-top: 16px;
      font-size: 20px;
    }
  }
  .form {
    width: 520px;
    padding: 24px 0 28px;
    .ant-form-item {
      margin-bottom: 32px;
    }
    .ant-form-explain {
      position: absolute;
      top: 50px;
    }
    .button {
      .ant-btn {
        width: 120px;
      }
    }
  }
}
</style>
